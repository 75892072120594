import axios from "../../../base";
import {ICreateSubscriptionRequest, ISubscription} from "./types";

class SubscriptionService {
    static async post(subscription: ICreateSubscriptionRequest) {
        try {
            const res = await axios.post(`/subscriptions`, subscription);
            return res.data as Promise<ISubscription>;
        } catch (e) {}
    }
}

export default SubscriptionService;