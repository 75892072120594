import { IButton } from "./index.types";
import styles from './styles.module.css';

const Button = ({
                    className='',
                    styleType='primary',
                    size='',
                    disabled= false,
                    onClick,
                    children,
                    type='button',
                    style,
                    icon
                }: IButton) => {
    return (
        <button
            style={style}
            onClick={onClick}
            className={`btn ${styleType} ${size} ${className} ${styles.submitBtn}`}
            type={type}
            disabled={disabled}
        >
            {
                icon &&
                <div className={styles.iconWrapper}>
                    <img src={icon} alt="...loading"/>
                </div>
            }
            {children}
        </button>
    );
};

export default Button;

