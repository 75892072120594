import generalStyles from "../pages/styles.module.css";
import {Collage} from "../../../components/decor/Collage/Collage";
import React from "react";

export const FormWithCollage = ({ children }: { children?: JSX.Element}) => {
    return (
        <div className={`${generalStyles.wrapper}`}>
            {children}
            <Collage />
        </div>
    );
};