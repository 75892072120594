import {Student} from "../student/types";

export interface ICreateTransactionRequest {
    name: string;
    phone: string;
    email: string;
    Amount: string;
    Opaque?: string;
    Timeout?: number;
    BackURL?: string;
    Currency?: string;
    Description?: string;
    CardHolderID?: string;
    description?: string;
}

export interface ICreateTransactionResponse extends ICreateTransactionRequest {
    paymentId: string;
    ResponseCode: string;
    ResponseMessage: string
}

export enum TransactionStatusEnum {
    PENDING = 'PENDING',
    SUCCESS = 'SUCCESS',
    FAILURE = 'FAILURE',
}

export interface IGetTransactionResponse {
    id: number
    name: string;
    email: string;
    paymentId: string;
    amount: string;
    status: TransactionStatusEnum;
    phone: string;
    orderId: number;
    account: Student
}
