import {INPUT_FORM_ITEMS} from "../../../shared/constants/inputConstanst";

export const PAYMENT_PLANS = {
    free: '0',
    standard: '19000',
    premium: '29000',
}

export const PAYMENT_IDS = {
    free: 1,
    standard: 2,
    premium: 3,
}

export enum PAYMENT_PLANS_ENUM {
    free = 'free',
    standard = 'standard',
    premium = 'premium',
}

export type PlanKey = keyof typeof PAYMENT_PLANS;

export const FORM_TITLE_MASSAGES = {
    free: 'Մուտք գործել հաշիվ',
    get standard() {
        return this.paidMassage.replace('plan', PAYMENT_PLANS_ENUM.standard).replace('currency', PAYMENT_PLANS.standard);
    },
    get premium() {
        return this.paidMassage.replace('plan', PAYMENT_PLANS_ENUM.premium).replace('currency', PAYMENT_PLANS.premium);
    },
    paidMassage: 'Դուք բաժանորդագրվելու եք plan փաթեթին Ձեզնից կգանձվի currency դրամ'

}

const PaymentFields = {
    name: true,
    phone: true,
    email: true,
    description: true,
}


const PAYMENT_FORM_ITEMS = INPUT_FORM_ITEMS.filter(({ name }) => PaymentFields[name as keyof typeof PaymentFields])

export { PAYMENT_FORM_ITEMS }

export const ERROR_MASSAGES = {
    unConfirmedPaymentMassage: 'Payment verification not confirmed, please check if the payment was successful? And try again'
}