import axios, { AxiosResponse, AxiosError } from 'axios';

import { loadState } from 'src/modules/shared/helpers/localStorage';

const logOut = (response: AxiosError) => {
    if (localStorage.getItem('token')) {
        localStorage.clear();
        return window.location.reload();
    }
    return response;
};

axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;

axios.defaults.headers.common = {
    Authorization: `Bearer ${loadState('token')}`,
};

const onResponse = (response: AxiosResponse) => response;

const onError = (error: AxiosError) => {
    if (error.response?.status === 401) {
        logOut(error);
    }

    return Promise.reject(
        error.response
            ? {
                code: error.response.status,
                data: error.response.data,
            }
            : { message: error },
    );
};

axios.interceptors.response.use(onResponse, onError,);

export default axios;
