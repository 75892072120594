import axios from "axios";
import {ICreateStudentRequest, Student, EdXStudent} from "./types";



class StudentService {
    static async post(student: ICreateStudentRequest, catchCallback: (err: any) => void) {
        try {
            const res = await axios.post(`${process.env.REACT_APP_BASE_URL}/students`, student);
            return res.data as Promise<Student & EdXStudent>;
        } catch (e: any) {
            catchCallback(e?.response?.data)
        }
    }

    static async getStudentByEmail(filterStudentRequest: Partial<ICreateStudentRequest>, catchCallback?: (err: any) => void) {
        try {
            const res = await axios.post(`${process.env.REACT_APP_BASE_URL}/students/getByEmail`, filterStudentRequest)
            if(res) {
                return res.data as Promise<Student>
            }
        } catch (e: any) {
            catchCallback && catchCallback(e?.data);
        }
    }
}

export default StudentService;