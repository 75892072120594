import { errorsMessages } from '../constants/errorMessages';

export const saveState = <T>(stateName: string, value: T) => {
    try {
        const serializedState = JSON.stringify(value);
        localStorage.setItem(stateName, serializedState);
    } catch (err) {
        throw new Error(errorsMessages.localStorageAdd);
    }
};

export const loadState = (stateName: string) => {
    try {
        const serializedState = localStorage.getItem(stateName);

        if (serializedState === null) {
            return undefined;
        }

        return JSON.parse(serializedState);
    } catch (err) {
        throw new Error(errorsMessages.localStorageLoad);
    }
};

export const removeState = (stateName: string) => {
    try {
        localStorage.removeItem(stateName);
    } catch (err) {
        throw new Error(errorsMessages.localStorageRemove);
    }
};
