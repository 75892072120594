import {INPUT_FORM_ITEMS} from "../../shared/constants/inputConstanst";

const registrationFields = {
    name: true,
    phone: true,
    password: true,
    email: true,
    username: true,
}


const REGISTRATION_FORM_ITEMS = INPUT_FORM_ITEMS.filter(({ name }) => registrationFields[name as keyof typeof registrationFields])


export { REGISTRATION_FORM_ITEMS }