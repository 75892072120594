import * as Yup from 'yup';

import { errorsMessages } from '../../shared/constants/errorMessages';
import { regex } from '../../shared/constants/regex';

export const CreateStudentSchema = () => {
    return Yup.object().shape({
        name: Yup.string()
            .required(errorsMessages.required),
        email: Yup.string()
            .required(errorsMessages.required)
            .matches(regex.emailRegEx, errorsMessages.email),
        username: Yup.string()
            .required(errorsMessages.required),
        phone: Yup.string()
            .required(errorsMessages.required),
        password: Yup.string().required(errorsMessages.required)
    })
};


