import {createBrowserRouter, useNavigate, useParams, useLocation} from "react-router-dom";
import React, {useEffect} from "react";

import Payment from 'src/modules/payment/pages/payment/payment';
import AmeriaError from 'src/modules/payment/pages/ameriaError/ameriaError';
import Layout from 'src/components/Layout';
import ErrorPage from 'src/modules/shared/pages/errorPage/errorPage'
import {PAYMENT_PLANS} from "../modules/payment/pages/payment/constants";
import {parseLocationSearch} from "../helpers/locationSearch";
import CreateStudent from "../modules/student/pages/register";
import AmeriaBack from "../modules/payment/pages/ameriaBack/ameriaBack";

const allowedPlansRegex = new RegExp(`^(${Object.keys(PAYMENT_PLANS).join('|')})$`);

const CheckPaymentPlan = () => {
    const location = useLocation();
    const queryParams =  parseLocationSearch(location.search);

    const navigate = useNavigate();
    const { plan } = useParams();

    useEffect(() => {
        if (!allowedPlansRegex.test(plan as string)) {
           navigate('/error', { state: { message: `${plan} payment plan does not exist` } });
            return
        }

        if(!queryParams.next) {
            navigate('/error', { state: { message: `Page not found` } });
        }

    },[plan, navigate, queryParams])

    return <Payment />;
};

const CheckPaymentSuccess = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const { plan, paymentID } = parseLocationSearch(location.search)

    useEffect(() => {
        if (!allowedPlansRegex.test(plan as string)) {
            navigate('/error', { state: { message: `Payment not found` } });
            return
        }
        // if (plan !== PAYMENT_PLANS_ENUM.free) {
        //     navigate('/ameria-back')
        // }
    },[location.search])

    return <CreateStudent />;
};



const router = createBrowserRouter([
    {
        path: "/",
        element: <Layout/>,
        errorElement: <ErrorPage />,
        children: [
            {
                path: "/error",
                element: <ErrorPage/>,
            },
            {
                path: "login/:plan",
                element: <CheckPaymentPlan/>,
            },
            {
                path: "ameria-back",
                element: <AmeriaBack />,
            },
            {
                path: "ameria-error",
                element: <AmeriaError/>,
            },
            {
                path: "register",
                element: <CheckPaymentSuccess/>,
            },
        ]
    },
]);

export default router;