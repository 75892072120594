import { useFormik } from 'formik'
import React, {useEffect, useState} from 'react';
import {useParams, useNavigate, useLocation, useSearchParams} from 'react-router-dom'
import { Button, Input } from "../../../../components/general";
import styles from '../../../shared/formStyles/secondaryForm.styles.module.css';
import {
    initPaymentInitialValues as initialValues,
    initPaymentSchema
} from '../../schemas/payment';
import { IPaymentForm } from "../../types";
import TransactionService from "src/api/techSchool/services/transaction";
import {
    PlanKey,
    FORM_TITLE_MASSAGES,
    PAYMENT_FORM_ITEMS,
    PAYMENT_IDS,
    PAYMENT_PLANS,
    PAYMENT_PLANS_ENUM
} from "../../pages/payment/constants";
import {parseLocationSearch} from "../../../../helpers/locationSearch";
import {redirectToCourse} from "../../../../helpers/course.helper";
import SubscriptionService from "../../../../api/techSchool/services/subscriptions";
import StudentService from "../../../../api/techSchool/services/student";

const {REACT_APP_REDIRECT_URL} = process.env;

const getBackUrl = (params: any) => {
    const next = params?.next.replaceAll(' ', '+')
    const urlWithNext = `${REACT_APP_REDIRECT_URL}?next=${next}&plan=${params.plan}&trackId=${params.trackId}`;
    const url = `${REACT_APP_REDIRECT_URL}?plan=${params.plan}`;

    return params.next ? urlWithNext  : url;
}

const InitPaymentForm: React.FC = () => {
    const [searchParams] = useSearchParams();
    const params = useParams();
    const navigate = useNavigate()
    const location = useLocation();
    const [plan, setPlan] = useState<PlanKey>(params.plan as PlanKey);
    const queryParams =  parseLocationSearch(location.search);
    const next = searchParams.get('next');

    const planListener = () => setPlan(params.plan as PlanKey);

    useEffect(planListener,[params.plan]);

    const errorHandle = (error: string) => {
        navigate('/ameria-error', { state: { from: location.pathname, message: error, navigateTitle: 'Go Back' }})
    }

    const handleNoneFreePlans = async (data: IPaymentForm) => {
        const response = await TransactionService.createTransaction({
            ...data,
            Amount: PAYMENT_PLANS[plan],
            BackURL: getBackUrl({...params, next})
        }, (e: any) => errorHandle(e));

        if(response?.ResponseCode && response) {
            window.location.href = `${process.env.REACT_APP_VPOS_PAYMENT_REDIRECT_URL}?id=${response.paymentId}`
        }
    };
    const handFreePlan = async (email: string) => {
        const { next, trackId } = queryParams;
        const student = await StudentService.getStudentByEmail({
            email
        }, (err: any) => {
            setFieldError('email', err.email)
        });
        if(student) {
            const subscription = await SubscriptionService.post({
                courseId: String(next).replaceAll(' ', '+'),
                planId: Number(PAYMENT_IDS[plan as keyof typeof PAYMENT_IDS]),
                studentId: student.id,
                trackId: Number(trackId)
            });
            subscription && redirectToCourse();
        }
    };

    const onSubmit = async (data: IPaymentForm) => {
        if(plan === PAYMENT_PLANS_ENUM.free) {
            await handFreePlan(data.email)
        } else {
            await handleNoneFreePlans(data)
        }
    };

    const validationSchema = initPaymentSchema(plan as PAYMENT_PLANS_ENUM);

    const {
        values,
        errors,
        touched,
        handleChange,
        handleSubmit,
        handleBlur,
        dirty,
        isValid,
        isSubmitting,
        setFieldError,
    } = useFormik({
        initialValues,
        validationSchema,
        onSubmit
    });

    const defineFormItems = params.plan === PAYMENT_PLANS_ENUM.free ? PAYMENT_FORM_ITEMS.filter((item) => item.name === 'email') : PAYMENT_FORM_ITEMS

    return (
            <div className={styles.formBody}>
                <div className={styles.titleWrapper}>
                    <h3>{FORM_TITLE_MASSAGES[params.plan as keyof typeof FORM_TITLE_MASSAGES]}</h3>
                </div>
                <form className={styles.form} onSubmit={handleSubmit}>
                    {
                        defineFormItems.map(({type, label, name,placeholder}) =>
                            <Input
                                type={type}
                                label={label}
                                name={name}
                                id={name}
                                touched={touched[name as keyof typeof touched]}
                                value={values[name as keyof typeof values]}
                                placeholder={placeholder}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                errorMessage={errors[name as keyof typeof errors]}
                                className={styles.formInput}
                                wrapperClassName={styles.inputWrapper}
                                key={name}
                            />
                        )
                    }
                    <div className={styles.btnWrapper}>
                        <Button
                            type='submit'
                            styleType='primary'
                            disabled={isSubmitting || !isValid || !dirty}
                            className={styles.submitBtn}
                        >
                            Մուտք գործել
                        </Button>
                    </div>
                </form>
            </div>
    );
};

export default InitPaymentForm;