import styles from './styles.module.css';
import logoBlack from '../../../assets/images/techschool_black.svg';
import collageSvg from '../../../assets/images/collage.svg';

export function Collage() {
    return (
        <div className={styles.wrapper}>
            <div className={styles.collageBody}>
                <div className={styles.logoWrapper}>
                    <img src={logoBlack} alt="...Loading"/>
                </div>
                <div className={styles.collage}>
                    <img src={collageSvg} alt="...Loading"/>
                </div>
            </div>
        </div>
    )
}