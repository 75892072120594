import React from 'react';

import InitPaymentForm from '../../forms/payment/payment';
import {FormWithCollage} from "../../../shared/formWithCollage";
import {SecondaryFormWrapper} from "../../../shared/formWrappers/secondaryFormWrapper";

const InitPayment = () => {
    return (
        <FormWithCollage>
            <SecondaryFormWrapper>
                <InitPaymentForm />
            </SecondaryFormWrapper>
        </FormWithCollage>
    );
};

export default InitPayment;
