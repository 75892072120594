import React, { useState } from 'react';

import CreateStudentForm from "../forms/student/student";
import {useLocation} from "react-router-dom";
import {parseLocationSearch} from "../../../helpers/locationSearch";
import {ICreatStudentForm} from "../types";
import {PAYMENT_IDS, PlanKey} from "../../payment/pages/payment/constants";
import StudentService from "../../../api/techSchool/services/student";
import SubscriptionService from "../../../api/techSchool/services/subscriptions";
import {redirectToCourse} from "../../../helpers/course.helper";
import {isClientError} from "../../shared/helper/error.helper";
import {Falsy} from "../../shared/types/general.types";
import { FormWithCollage } from '../../shared/formWithCollage';
import {SecondaryFormWrapper} from "../../shared/formWrappers/secondaryFormWrapper";

const CreateStudent = () => {
    const location = useLocation();
    const queryParams =  parseLocationSearch(location.search);
    const [formError, setFormError] = useState<Partial<ICreatStudentForm> | Falsy>();

    const handleCreateStudentFailure = (error: any) => {
        if(isClientError(error?.status)) {
            setFormError(error?.data)
        }
    }

    const onSubmit = async (data: ICreatStudentForm) => {
            const student = await StudentService.post({
                username: data.username,
                email: data.email,
                name: data.name,
                phone: data.phone,
                password: data.password
            }, (err: any) => handleCreateStudentFailure(err));

            if(student) {
                const plan = queryParams.plan as PlanKey;
                const next = String(queryParams.next).replaceAll(' ', '+')

                const subscription = await SubscriptionService.post({
                    planId: PAYMENT_IDS[plan],
                    courseId: next,
                    studentId: student.id,
                    trackId: Number(queryParams.trackId)
                });

                subscription &&  redirectToCourse()
            }
    };

    return (
                    <FormWithCollage>
                        <SecondaryFormWrapper>
                            <CreateStudentForm
                                initialValues={{
                                    name: '',
                                    email: '',
                                    phone: '',
                                    username: '',
                                    password: '',
                                } as any}
                                onSubmit={onSubmit}
                                err={formError}
                            />
                        </SecondaryFormWrapper>
                    </FormWithCollage>
    );
};

export default CreateStudent;
