import { useFormik } from 'formik'
import React, {useEffect} from 'react';
import { Button, Input } from "../../../../components/general";
import styles from '../../../shared/formStyles/secondaryForm.styles.module.css';
import { CreateStudentSchema as validationSchema} from '../../schemas/student';
import {ICreatStudentForm} from "../../types";
import {Falsy} from "../../../shared/types/general.types";
import {useLocation, useNavigate} from "react-router-dom";
import {PAYMENT_PLANS_ENUM} from "../../../payment/pages/payment/constants";
import {parseLocationSearch} from "../../../../helpers/locationSearch";
import {Loader} from "../../../../components/display/Loader/Loader";
import {REGISTRATION_FORM_ITEMS} from "../../pages/constants";
import {Recommendation} from "../../../shared/recomendation/recomendation";

interface ICreateStudentProps {
    initialValues: ICreatStudentForm;
    onSubmit: (data: ICreatStudentForm) => Promise<void>;
    err: Partial<ICreatStudentForm> | Falsy
}

const CreateStudentForm: React.FC<ICreateStudentProps> = ({ initialValues, onSubmit, err }) => {
    const location = useLocation();
    const queryParams =  parseLocationSearch(location.search);
    const navigate = useNavigate();

    const {
        values,
        errors,
        touched,
        handleChange,
        handleSubmit,
        handleBlur,
        dirty,
        isValid,
        isSubmitting,
        setFieldError,
    } = useFormik({
        initialValues,
        validationSchema,
        onSubmit
    });

    useEffect(() => {
        err &&
        Object.keys(err).map((key) => {
            setFieldError(key, err[key as keyof typeof err])
        })
    }, [err])

    const handleNavigate = () => {
        const {plan, next, trackId} = queryParams;
        navigate(`/login/${plan}?next=${next}&trackId=${trackId}`)
    }


    return (
        <>
            {
                isSubmitting ? <Loader /> :
                    <div className={styles.formBody}>
                        <div className={styles.titleWrapper}>
                            <h3>
                                {queryParams.plan !== PAYMENT_PLANS_ENUM.free ? <>Վճարումը հաջողությամբ կատարվել է.<br/>
                                        ձեր հաշիվը ստեղծելու համար լրացրեք համապատասխան դաշտերը </> :
                                    <>Ստեղծել հաշիվ</> }
                            </h3>
                        </div>
                        <form className={styles.form} onSubmit={handleSubmit}>
                            {
                                REGISTRATION_FORM_ITEMS.map(({type, label, name,placeholder}) =>
                                    <Input
                                        type={type}
                                        label={label}
                                        name={name}
                                        id={name}
                                        touched={touched[name as keyof typeof touched]}
                                        value={values[name as keyof typeof values]}
                                        placeholder={placeholder}
                                        handleChange={handleChange}
                                        handleBlur={handleBlur}
                                        errorMessage={errors[name as keyof typeof errors]}
                                        className={styles.formInput}
                                        wrapperClassName={styles.inputWrapper}
                                        key={name}
                                    />
                                )
                            }
                            <div className={styles.btnWrapper}>
                                <Button
                                    type='submit'
                                    styleType='primary'
                                    disabled={isSubmitting || !isValid || !dirty}
                                    className={styles.submitBtn}
                                >
                                    Գրանցվել
                                </Button>
                            </div>
                        </form>
                        <Recommendation massage='Ունե՞ս հաշիվ' handleNavigate={handleNavigate}/>
                    </div>
            }
        </>
    );
};

export default CreateStudentForm;