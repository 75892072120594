import axios from "../../../base";
import {
    ICreateTransactionRequest, ICreateTransactionResponse, IGetTransactionResponse,
} from "./types";

class TransactionService {
    static async createTransaction(transaction: ICreateTransactionRequest, catchCallback: (e: any) => void) {
        try {
            const res = await axios.post(`/payment-transactions`, transaction);
            return res.data as Promise<ICreateTransactionResponse>;
        } catch (e) {
            catchCallback(e);
        }
    }
    static async getTransaction(PaymentID: string) {
        try {
            const res = await axios.get(`/payment-transactions/${PaymentID}`);
            return res.data as Promise<IGetTransactionResponse>;
        } catch (e) {
        }
    }
}

export default TransactionService;