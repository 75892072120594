import * as Yup from 'yup';

import { errorsMessages } from '../../shared/constants/errorMessages';
import { regex } from '../../shared/constants/regex';
import {PAYMENT_PLANS_ENUM} from "../pages/payment/constants";

export const initPaymentInitialValues = {
    name: '',
    phone: '',
    email: '',
    description: ''
}

const SchemaItems = {
    name: Yup.string()
        .required(errorsMessages.required),
    email: Yup.string()
        .required(errorsMessages.required)
        .matches(regex.emailRegEx, errorsMessages.email),
    phone: Yup.string()
        .required(errorsMessages.required),
    description: Yup.string().default('New order!')
}

export const initPaymentSchema = (plan: PAYMENT_PLANS_ENUM) => {
   const schema =  plan === PAYMENT_PLANS_ENUM.free ? { email: SchemaItems.email } : SchemaItems

   return () =>  Yup.object().shape(schema)
};

