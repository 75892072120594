import React, {useEffect} from 'react';
import {useLocation, useNavigate} from "react-router-dom";
import {parseLocationSearch} from "../../../../helpers/locationSearch";
import {Loader} from "../../../../components/display/Loader/Loader";
import {TransactionStatusEnum} from "../../../../api/techSchool/services/transaction/types";
import {FormWithCollage} from "../../../shared/formWithCollage";
import {SecondaryFormWrapper} from "../../../shared/formWrappers/secondaryFormWrapper";
import TransactionService from "../../../../api/techSchool/services/transaction";
import {redirectToCourse} from "../../../../helpers/course.helper";
import SubscriptionService from "../../../../api/techSchool/services/subscriptions";
import {ERROR_MASSAGES, PAYMENT_IDS, PlanKey} from "../payment/constants";

const AmeriaBack:React.FC = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const queryParams =  parseLocationSearch(location.search);

    const init = async () => {
        const { plan, next, trackId, paymentID} = queryParams;
        const transaction = await TransactionService.getTransaction(paymentID as string);
            if(transaction?.status !== TransactionStatusEnum.SUCCESS) {
                navigate('/error', { state: {  message: ERROR_MASSAGES.unConfirmedPaymentMassage }})
            } else if (transaction.account) {
                const subscription = await SubscriptionService.post({
                    planId: PAYMENT_IDS[plan as PlanKey],
                    courseId: String(next).replaceAll(' ', '+') as string,
                    studentId: transaction.account.id,
                    trackId: Number(queryParams.trackId)
                });

                subscription &&  redirectToCourse()
            } else {
                navigate(`/register?plan=${plan}&next=${next}&trackId=${trackId}`)
            }
    }

    useEffect(() => {
        setTimeout(() => {
            (async () => {
                await init();
            })();
        }, 2000)
    }, []);




    return ( <FormWithCollage>
                <SecondaryFormWrapper>
                    <Loader />
                </SecondaryFormWrapper>
            </FormWithCollage>
    );
};

export default AmeriaBack;