import React from 'react';
import ErrorPage, {DefaultErrorBody} from '../../../shared/pages/errorPage/errorPage';
import paymentErrIcon from '../../../../assets/images/error.svg';

import { useLocation } from 'react-router-dom'

const AmeriaError: React.FC = () => {
    const location = useLocation();

    return  (
        <ErrorPage>
            <DefaultErrorBody errIcon={paymentErrIcon} err={location.state?.message}/>
        </ErrorPage>
    )
};

export default AmeriaError;