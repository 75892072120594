export const INPUT_FORM_ITEMS = [
    {
        type: 'text',
        label: 'Անուն',
        name: 'name',
        placeholder: 'Խնդրում ենք մուտքագրել ձեր անունը'
    },
    {
        type: 'text',
        label: 'Էլ. հասցե',
        name: 'email',
        placeholder: 'Խնդրում ենք մուտքագրել ձեր  էլ.հասցեն'
    },
    {
        type: 'text',
        label: 'Օգտանուն',
        name: 'username',
        placeholder: 'Խնդրում ենք մուտքագրել ձեր մուտքանունը'
    },
    {
        type: 'phone',
        label: 'Հեռախոսահամար',
        name: 'phone',
        placeholder: 'Խնդրում ենք մուտքագրել ձեր հեռախոսահամար'
    },
    {
        type: 'password',
        label: 'Գաղտնաբառ',
        name: 'password',
        placeholder: 'Խնդրում ենք մուտքագրել ձեր գաղտնաառը'
    },
    {
        type: 'text',
        label: 'Մեկնաբանություն',
        name: 'description',
        placeholder: 'Լրացուցիչ մեկնաբանություն'
    },
]
