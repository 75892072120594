import styles from './styles.module.css';
import React from "react";
import {Button} from "../../../components/general";

interface SignUpRecommendationProps {
    massage: string;
    handleNavigate: () => void
}


export function Recommendation({ massage, handleNavigate }: SignUpRecommendationProps) {
    return (
        <div className={styles.wrapper}>
            <div className={styles.recommendMassage}>{massage}</div>
            <Button
                type='button'
                styleType='primary'
                disabled={false}
                className={styles.redirectBtn}
                onClick={handleNavigate}
            >
                Մուտք գործել
            </Button>
        </div>
    )
}